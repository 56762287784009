import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Snake And Ladder Problem | A Helpful Line-by-Line Code Tutorial"
				}
				subHeader={""}
				description={
					"Given a snake and ladder board, find the minimum number of dice throws required to reach the destination or last cell from source or 1st cell."
				}
				keywords={[]}
				embeddedVideo={"19tY6Y90TP0"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/594cbb44a9015291056de6cf175f6dba/raw/43d0b0adc0856319aecde87a70bc023a6df8c716/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
